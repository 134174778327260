.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}


#root {
  display: initial !important; /* Use !important to ensure the override */
}

.input-wrapper {
  width: 80%;
  aspect-ratio: 3/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}
.input-container {
  width: 40%;
  height: 60%;
  border: 2px dashed #000000;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.input-field {
  display: block;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.file-info span {
  font-size: 16px;
  font-weight: 500;
}

.modal-90w {
  max-width: 90%!important;
}

.choose-title {
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

.accept-title {
  font-size: 14px;
}

